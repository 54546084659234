<template>
  <v-navigation-drawer
    app
    floating
    :value="drawer"
    :mini-variant="miniVariant"
    :permanent="$vuetify.breakpoint.mdAndUp"
    dark
    color="#31343C"
    class="sidebar"
    mini-variant-width="73"
  >
    <div class="scrollable__container">
      <div class="scrollable__section">
        <div
          class="scrollable__header pa-4"
          :class="{ 'elevation-4': scrolled }"
        >
          <ul class="pa-0 d-flex justify-content-between mb-5">
            <!-- <router-link :to="{ name: 'Store home' }"> -->
            <a href="/">
              <li v-if="!miniVariant" class="py-3 px-0">
                <img
                  src="/img/tumar__logo--white.svg"
                  alt="tumar__logo--white"
                  title="tumar__logo--white"
                />
              </li>
            </a>
            <!-- </router-link> -->
            <li
              v-if="$vuetify.breakpoint.mdAndUp"
              style="flex: unset"
              class="pa-0 d-flex align-items-center cursor-pointer"
              :class="{ 'mx-auto': miniVariant }"
              @click="$emit('collapseDrawer')"
            >
              <v-icon>mdi-menu-open</v-icon>
            </li>
          </ul>
          <div class="mb-10">
            <div class="d-flex">
              <v-text-field
                v-show="!miniVariant"
                v-model="searchNavLink"
                flat
                dense
                solo
                placeholder="Search keywords..."
                hide-details
                class="rounded"
                append-icon=""
                background-color="#40434B"
                hide-no-data
                ref="linkSearchBar"
              >
                <v-icon slot="prepend-inner" color="#b6b6b6" class="mr-3">
                  fas fa-search
                </v-icon>
              </v-text-field>
              <v-btn
                v-show="miniVariant"
                :elevation="0"
                class="rounded pa-0"
                style="min-height: 38px; min-width: unset; width: 100%"
                color="#40434B"
                :ripple="false"
                @click="openSearchField"
                ><v-icon size="16">{{
                  searchNavLink ? 'fas fa-times' : 'fas fa-search'
                }}</v-icon></v-btn
              >
            </div>
          </div>
        </div>
        <div class="scrollable__content">
          <v-list dark>
            <v-list-item
              v-for="(link, index) in navLinks"
              :key="index"
              dense
              :to="{ name: link.to }"
              :class="{ 'mb-4': index !== navLinks.length - 1 }"
            >
              <v-badge
                color="#99D093"
                dot
                v-if="miniVariant && link.to === 'chats'"
              >
                <v-list-item-icon class="ma-0">
                  <img :src="link.icon" :alt="getImageName(link.icon)" :title="getImageName(link.icon)" 
                  />
                </v-list-item-icon>
              </v-badge>
              <v-list-item-icon :class="{ 'mr-5': !miniVariant }" v-else>
                <img :src="link.icon" :alt="getImageName(link.icon)" :title="getImageName(link.icon)" 
                />
              </v-list-item-icon>

              <v-list-item-content>
                <v-list-item-title>{{ link.title }}</v-list-item-title>
              </v-list-item-content>
              <v-list-item-icon v-if="link.to === 'chats'">
                <v-badge content="7" color="#99D093"></v-badge>
              </v-list-item-icon>
            </v-list-item>
          </v-list>
        </div>
        <div class="scrollable__footer">
          <div v-if="!miniVariant" class="pa-5">
            <button class="logout" @click="handleLogout">{{
              this.$t('AUTH.LOGOUT')
            }}</button>
          </div>
        </div>
      </div>
    </div>
  </v-navigation-drawer>
</template>

<script>
import { createNamespacedHelpers } from 'vuex'
const { mapGetters, mapActions } = createNamespacedHelpers('auth')
import { getImageName } from '@/utils/utils'

export default {
  name: 'NavigationDrawer',
  props: {
    drawer: {
      type: Boolean,
      default: true,
    },
    miniVariant: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      searchNavLink: '',
      scrolled: false,
      craftsmanMenu: [
        {
          title: 'Messages',
          icon: '/img/icons/Group.svg',
          to: 'chat',
          disabled: false,
        },
        {
          title: 'Company page',
          icon: '/img/icons/Document.svg',
          to: 'craftsman-company-page',
          disabled: false,
        },
        {
          title: 'Reviews',
          icon: '/img/icons/Document.svg',
          to: 'craftsman-reviews',
          disabled: false,
        },
        {
          title: 'Order history',
          icon: '/img/icons/Folder.svg',
          disabled: false,
          to: 'craftsman-order-history',
          subLinks: [],
        },
        {
          title: 'Profile',
          icon: '/img/icons/Document.svg',
          to: 'craftsman-profile',
          disabled: false,
        },
      ],
    }
  },

  methods: {
    getImageName,
    ...mapActions(['logout']),
    onScroll(e) {
      if (e.target.scrollTop > 0) {
        this.scrolled = true
      } else {
        this.scrolled = false
      }
    },
    handleLogout() {
      this.logout()
      this.scrolled = !this.scrolled
    },
    openSearchField() {
      this.$emit('collapseDrawer')
      setTimeout(() => {
        this.$refs.linkSearchBar.focus()
      }, 0)
    },
  },

  computed: {
    ...mapGetters(['getCurrentUserName', 'currentUser', 'isCraftsmanLogged']),
    navLinksForSearch() {
      const result = this.navLinks.filter(
        (link) => link.title !== 'История заказов' && link.title !== 'Переписка'
      )
      return result
    },
    navLinks() {
      if (this.currentUser?.role === 'retail_client') {
        return this.$t('RETAIL_MENU')
        return this.$t('RETAIL_MENU')
            .filter((l) => l.title.toLowerCase().includes(this.searchNavLink.toLowerCase()))
          .map((l) => {
            if (this.currentUser?.role === 'retail_client' && l.to === 'chats') {
              l.to = 'chat'
            }

            return l
          })
      } else if (
        this.currentUser?.role === 'manager' ||
        this.currentUser?.role === 'admin') {
        return this.$t('REYESTR_MENU').filter((l) =>
            l.title.toLowerCase().includes(this.searchNavLink.toLowerCase())
          )
          .map((l) => {
            if (this.currentUser?.role === 'retail_client' && l.to === 'chats') {
              l.to = 'chat'
            }

            return l
          })
      } else if (this.isCraftsmanLogged) {
        return this.craftsmanMenu
            .filter((l) => l.title.toLowerCase().includes(this.searchNavLink.toLowerCase()))
            .map((l) => {
              if (this.currentUser?.role === 'retail_client' && l.to === 'chats') {
                l.to = 'chat'
              }
              return l
            })
      }
      return []
    },
  },
  mounted() {
    this.drawer = true
  },
}
</script>
<style lang="scss" scoped>
.scrollable {
  &__container {
    position: absolute;
    inset: 0;
    display: flex;
    flex-direction: column;
  }
  &__section {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    min-height: 0;
  }
  &__content {
    flex-grow: 1;
    overflow: auto;
    min-height: 0;
    .v-list {
      overflow: hidden;
    }
    .v-list-item__title {
      font-size: 15px;
    }
    .v-list-item--link {
      transition: transform 0.2s;
      &:hover,
      &:focus {
        &:before {
          content: none;
        }
        transform: scale(1.04);
      }
    }
    .v-list-item--active {
      color: inherit;
      position: relative;
      &:hover,
      &:focus {
        transform: unset;
      }
      &:before {
        content: none;
      }
      &:after {
        position: absolute;
        right: 6px;
        width: 3px;
        height: 100%;
        background-color: white;
        border-radius: 10px;
      }
    }
  }
  &__footer {
    height: fit-content;
  }
}
.sidebar {
  &__menu {
    li {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 17px;
      cursor: pointer;

      &:hover {
        a {
          transform: scale(1.1);
          color: #ffffff;
        }

        .arrow-down-circle {
          // background-image: url('../../assets/icons/arrow-down-circle-icon.svg');
          background-repeat: no-repeat;
          width: 24px;
          height: 24px;
          display: inline-block;
        }
      }
    }

    a {
      font-family: 'Inter', sans-serif;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 34px;
      color: rgba(255, 255, 255, 0.6);
      display: flex;
      flex-direction: row;
      align-items: center;
    }
  }
}

.message-link {
  &::before {
    content: '';
    // background-image: url('../../assets/icons/message-icon.svg');
    background-repeat: no-repeat;
    width: 24px;
    height: 24px;
    display: inline-block;
    margin-right: 12px;
  }
}

.main__link {
  &::before {
    content: '';
    // background-image: url('../../assets/icons/home-icon.svg');
    background-repeat: no-repeat;
    width: 24px;
    height: 24px;
    display: inline-block;
    margin-right: 12px;
  }
}

.profile__link {
  &::before {
    content: '';
    // background-image: url('../../assets/icons/document-icon.svg');
    background-repeat: no-repeat;
    width: 24px;
    height: 24px;
    display: inline-block;
    margin-right: 12px;
  }
}

.order__link {
  &::before {
    content: '';
    // background-image: url('../../assets/icons/folder-icon.svg');
    background-repeat: no-repeat;
    width: 24px;
    height: 24px;
    display: inline-block;
    margin-right: 12px;
  }
}

.price__link {
  &::before {
    content: '';
    // background-image: url('../../assets/icons/category-icon.svg');
    background-repeat: no-repeat;
    width: 24px;
    height: 24px;
    display: inline-block;
    margin-right: 12px;
  }
}

.registry__link {
  &::before {
    content: '';
    // background-image: url('../../assets/icons/paper-icon.svg');
    background-repeat: no-repeat;
    width: 24px;
    height: 24px;
    display: inline-block;
    margin-right: 12px;
  }
}

.invoice__link {
  &::before {
    content: '';
    // background-image: url('../../assets/icons/paper-icon.svg');
    background-repeat: no-repeat;
    width: 24px;
    height: 24px;
    display: inline-block;
    margin-right: 12px;
  }
}

.message-notif {
  display: inline-block;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  background-color: #99d093;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: 0.75px;
  color: #ffffff;
  text-align: center;
}

.logo-holder {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-top: 28px;
}

.menu-toggle-icon {
  display: inline-block;
  width: 20px;
  height: 20px;
  cursor: pointer;
}

.logout {
  font-family: 'Inter', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 34px;
  letter-spacing: 0.75px;
  display: inline-block;
  width: 100%;
  padding: 17px 0;
  color: #ffffff;
  border-radius: 10px;
  background: rgba(255, 255, 255, 0.1);
}

.cursor-pointer {
  cursor: pointer;
}
</style>
<style>
.scrollable__content .v-list-item__icon .v-badge__badge {
  inset: unset !important;
}
</style>
