var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"d-flex text-center flex-column product cursor-pointer",on:{"click":function($event){return _vm.$emit('goToProduct', _vm.item)}}},[(_vm.item.price_type === 'reyestr')?_vm._l((_vm.item.colors),function(color){return _c('div',{key:color.id,staticClass:"justify-space-between d-flex w-100"},[_c('div',{staticClass:"d-flex align-start w-100"},[_c('div',{staticClass:"mr-5 product__img"},[_c('img',{staticStyle:{"width":"100%","height":"100%","display":"block","object-fit":"cover"},attrs:{"src":_vm.item && _vm.baseUrl + _vm.item.photo,"alt":_vm.getImageName(_vm.item.photo),"title":_vm.getImageName(_vm.item.photo)}})]),_c('div',{staticClass:"w-100"},[_c('h3',{staticClass:"product__title"},[_vm._v(" "+_vm._s(_vm.item.name[_vm.activeLanguage.language])+" ")]),_c('span',{staticClass:"product__options d-block"},[_vm._v(" Цвет: "+_vm._s(color.name)+" ")]),_c('div',{staticClass:"product__options d-flex flex-column"},_vm._l((_vm.item.sizes.filter(
                (size) => size.colorId === color.id
              )),function(size){return _c('span',{key:size.id},[_vm._v(" Размер: "+_vm._s(`${size.name} * ${size.count}`)+" ")])}),0),_c('h3',[_c('span',{staticClass:"count"},[_vm._v(_vm._s(_vm.item.sizes .filter((size) => size.colorId === color.id) .reduce((sum, curr) => (sum += curr.count), 0)))]),_c('span',{staticClass:"delimetr"},[_vm._v(" x ")]),_c('span',{staticClass:"price"},[_c('span',{staticClass:"currency"},[_vm._v("$")]),_c('span',{staticClass:"value-price"},[_vm._v(_vm._s(_vm.item.sizes.filter((size) => size.colorId === color.id)[0] .price))])])])])]),_c('v-btn',{staticClass:"px-0 d-flex align-start",attrs:{"icon":"","ripple":false},on:{"click":function($event){$event.stopPropagation();return _vm.removeItem({
            type: _vm.item.price_type,
            itemId: _vm.item.id,
            colorId: color.id,
          })}}},[_c('v-icon',[_vm._v("mdi-close")])],1)],1)}):_vm._e(),_vm._l((_vm.item.soles),function(sole){return _c('div',{key:sole.id,staticClass:"align-center d-flex flex-column"},_vm._l((_vm.item.colors.filter(
          (color) => color.soleId === sole.id
        )),function(color){return _c('div',{key:color.id,staticClass:"d-flex justify-space-between w-100"},[_c('div',{staticClass:"d-flex align-start w-100"},[_c('div',{staticClass:"mr-5 product__img"},[_c('img',{attrs:{"src":_vm.baseUrl + color.photo_path,"alt":_vm.getImageName(color.photo_path),"title":_vm.getImageName(color.photo_path)}}),_c('img',{staticStyle:{"position":"absolute","top":"0"},attrs:{"src":_vm.item && _vm.baseUrl + sole.photo_path,"alt":_vm.getImageName(sole.photo_path),"title":_vm.getImageName(sole.photo_path)}})]),_c('div',{staticClass:"w-100"},[_c('h3',{staticClass:"product__title"},[_vm._v(" "+_vm._s(_vm.item.name[_vm.activeLanguage.language])+" ")]),_c('span',{staticClass:"product__options d-block"},[_vm._v(" Подошва: "+_vm._s(sole.name)+" ")]),(_vm.item.colors)?_c('span',{staticClass:"product__options d-block"},[_vm._v(" Цвет: "+_vm._s(color.name)+" ")]):_vm._e(),_c('div',{staticClass:"product__options d-flex flex-column"},_vm._l((_vm.item.sizes.filter(
                  (size) =>
                    size.soleId === sole.id && size.colorId === color.id
                )),function(size,index){return _c('span',{key:index},[_vm._v(" Размер: "+_vm._s(`${size.name} * ${size.count}`)+" ")])}),0),_c('h3',[_c('span',{staticClass:"count"},[_vm._v(_vm._s(_vm.item.sizes .filter( (size) => size.soleId === sole.id && size.colorId === color.id ) .reduce((sum, curr) => (sum += curr.count), 0)))]),_c('span',{staticClass:"delimetr"},[_vm._v(" x ")]),_c('span',{staticClass:"price"},[_c('span',{staticClass:"currency"},[_vm._v("$")]),_c('span',{staticClass:"value-price"},[_vm._v(_vm._s(_vm.item.sizes.filter( (size) => size.soleId === sole.id && size.colorId === color.id )[0] && _vm.item.sizes.filter( (size) => size.soleId === sole.id && size.colorId === color.id )[0].price))])])])])]),_c('v-btn',{staticClass:"px-0 d-flex align-start",attrs:{"icon":"","ripple":false},on:{"click":function($event){$event.stopPropagation();return _vm.removeItem({
              type: _vm.item.price_type,
              itemId: _vm.item.id,
              colorId: color.id,
              soleId: sole.id,
              soles: _vm.item.soles,
            })}}},[_c('v-icon',[_vm._v("mdi-close")])],1)],1)}),0)})],2)
}
var staticRenderFns = []

export { render, staticRenderFns }