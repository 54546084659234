<template>
  <v-card class="side-cart" :elevation="0" tile>
    <div class="side-cart__container">
      <SideCartAdminHeader @toggleSideCart="(value) => $emit('toggleSideCart', value)" />
      <v-divider style="border-color: #e1e1e1" class="my-0"></v-divider>
      <div class="side-cart__body pa-4">
        <SideCartAdminItem v-for="item in cartItems.filter((i) => i.price_type === 'reyestr')" :key="item.cartId"
          class="d-flex text-center justify-space-between product cursor-pointer"
          @goToProduct="(item) => $emit('goToProduct', item)"
          @removeCartItem="(payload) => $emit('removeCartItem', payload)" :item="item" />
        <SideCartAdminItem v-for="item in cartItems.filter((i) => i.price_type === 'sole')" :key="item.cartId"
          class="d-flex text-center justify-space-between product cursor-pointer"
          @goToProduct="(item) => $emit('goToProduct', item)"
          @removeCartItem="(cartId) => $emit('removeCartItem', cartId)" :item="item" />
      </div>
      <!-- Moda-total -->

      <v-divider style="border-color: #e1e1e1" class="my-0"></v-divider>
      <SideCartAdminFooter @openBasket="$emit('openBasket')" :totalPrice="getItemsFullPrice" />
    </div>
  </v-card>
</template>

<script>
import SideCartAdminHeader from './SideCartAdminHeader.vue'
import SideCartAdminItem from './SideCartAdminItem.vue'
import SideCartAdminFooter from './SideCartAdminFooter.vue'
export default {
  props: {
    cartItems: {
      type: Array,
      default: () => [],
    },
  },
  components: {
    SideCartAdminHeader,
    SideCartAdminItem,
    SideCartAdminFooter,
  },
  computed: {
    getItemsFullPrice() {
      let fullPrice = 0;
      let sizePrice = 0;
      this.cartItems?.forEach((item) => {
        const brandsPrice = item.price_type === 'reyestr'
          ? item.selected_brands
          : item.soles.map((el) => el.selected_brands).flat()

        const count = item.sizes.reduce((acc, curr) => {
          return (acc += curr.count)
        }, 0)

        fullPrice += brandsPrice.reduce((acc, curr) => {
          if (curr.boughtBrandsKit) {
            return (acc += curr.brandPrice * count + (curr.boughtBrandsKit * curr.brandsPrice))
          } else {
            return (acc += curr.brandPrice * count)
          }
        }, 0)
        sizePrice += item.sizes.reduce((acc, curr) => {
          return (acc += curr.count * curr.price)
        }, 0)
      })
      this.$emit('getFullPrice', +fullPrice + +sizePrice)
      return (+fullPrice + +sizePrice)
        .toString()
        .replace(/\B(?=(\d{3})+(?!\d))/g, ' ')
    },
  }
}
</script>

<style lang="scss" scoped>
.side-cart {
  position: fixed;
  top: 0;
  right: 0;
  width: 344px;
  height: 100%;
  z-index: 7;

  &__container {
    display: flex;
    flex-direction: column;
    height: 100%;
  }

  &__body {
    flex-grow: 1;
    overflow: auto;
  }

  &__footer {
    .columns {
      span {
        font-size: 24px;
        color: #666666;
        font-family: 'Roboto Condensed';
      }

      &>span {
        font-weight: 500;
        font-family: 'Alegreya Sans';
      }
    }
  }

  .product {
    &__title {
      font-size: 16px;
      margin-bottom: 6px;
    }

    &__options {
      font-size: 14px;
      color: #b2b2b2;
    }

    &__title,
    &__options {
      text-align: left;
    }
  }

  .view {
    background-color: #cfebcc;
  }

  .ordering {
    background-color: #eeeeee;
  }
}

.product {
  &__title {
    font-size: 24px;
    font-weight: 500;
  }

  .count,
  .delimetr {
    font-size: 14px;
    color: #9a9a9a;
  }

  .price {
    font-size: 18px;
    color: #666666;
  }
}

.v-btn--icon:before {
  content: none;
}
</style>
