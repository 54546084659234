<template>
  <div class="side-cart__footer container-fluid px-3 pb-12 mt-8">
    <div class="columns">
      <span>{{ $t('CART_FOOTER.summary') }}:</span>

      <div>
        <span>${{ Number(totalPrice.replace(/\s/g, '')).toFixed(2) }}</span>
      </div>
    </div>
    <div>
      <button
        type="button"
        class="btn view mx-auto mb-3"
        data-dismiss="modal"
        @click="$emit('openBasket')"
      >
        <span>{{ $t('CART_FOOTER.view') }}</span>
      </button>
      <button type="button" class="btn ordering mx-auto">
        {{ $t('CART_FOOTER.ordering') }}
      </button>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    totalPrice: {
      type: String,
      default: '0',
    },
  },
}
</script>

<style></style>

<style lang="scss" scoped>
.side-cart {
  position: fixed;
  top: 0;
  right: 0;
  width: 344px;
  height: 100%;
  z-index: 7;
  &__container {
    display: flex;
    flex-direction: column;
    height: 100%;
  }
  &__body {
    flex-grow: 1;
    overflow: auto;
  }
  &__footer {
    .columns {
      span {
        font-size: 24px;
        color: #666666;
        font-family: 'Roboto Condensed';
      }
      & > span {
        font-weight: 500;
        font-family: 'Alegreya Sans';
      }
    }
  }
  .product {
    &__title {
      font-size: 16px;
      margin-bottom: 6px;
    }
    &__options {
      font-size: 14px;
      color: #b2b2b2;
    }
    &__title,
    &__options {
      text-align: left;
    }
  }
  .view {
    background-color: #cfebcc;
  }
  .ordering {
    background-color: #eeeeee;
  }
}
.product {
  &__title {
    font-size: 24px;
    font-weight: 500;
  }
  .count,
  .delimetr {
    font-size: 14px;
    color: #9a9a9a;
  }
  .price {
    font-size: 18px;
    color: #666666;
  }
}
.v-btn--icon:before {
  content: none;
}
</style>
