<template>
  <div
    class="d-flex text-center flex-column product cursor-pointer"
    @click="$emit('goToProduct', item)"
  >
    <template v-if="item.price_type === 'reyestr'">
      <div
        v-for="color in item.colors"
        :key="color.id"
        class="justify-space-between d-flex w-100"
      >
        <div class="d-flex align-start w-100">
          <div class="mr-5 product__img">
            <img
              :src="item && baseUrl + item.photo"
              :alt="getImageName(item.photo)" :title="getImageName(item.photo)" 
              style="
                width: 100%;
                height: 100%;
                display: block;
                object-fit: cover;
              "
            />
          </div>

          <div class="w-100">
            <h3 class="product__title">
              {{ item.name[activeLanguage.language] }}
            </h3>
            <span class="product__options d-block">
              Цвет: {{ color.name }}
            </span>
            <div class="product__options d-flex flex-column">
              <span
                v-for="size in item.sizes.filter(
                  (size) => size.colorId === color.id
                )"
                :key="size.id"
              >
                Размер: {{ `${size.name} * ${size.count}` }}
              </span>
            </div>
            <h3>
              <span class="count">{{
                item.sizes
                  .filter((size) => size.colorId === color.id)
                  .reduce((sum, curr) => (sum += curr.count), 0)
              }}</span>
              <span class="delimetr"> x </span>
              <span class="price">
                <span class="currency">$</span>
                <span class="value-price">{{
                  item.sizes.filter((size) => size.colorId === color.id)[0]
                    .price
                }}</span>
              </span>
            </h3>
          </div>
        </div>

        <v-btn
          class="px-0 d-flex align-start"
          icon
          :ripple="false"
          @click.stop="
            removeItem({
              type: item.price_type,
              itemId: item.id,
              colorId: color.id,
            })
          "
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </div>
    </template>
    <template else>
      <div
        v-for="sole in item.soles"
        :key="sole.id"
        class="align-center d-flex flex-column"
      >
        <div
          v-for="color in item.colors.filter(
            (color) => color.soleId === sole.id
          )"
          :key="color.id"
          class="d-flex justify-space-between w-100"
        >
          <div class="d-flex align-start w-100">
            <div class="mr-5 product__img">
              <img :src="baseUrl + color.photo_path" :alt="getImageName(color.photo_path)" :title="getImageName(color.photo_path)" 
              />
              <img
                :src="item && baseUrl + sole.photo_path"
                :alt="getImageName(sole.photo_path)" :title="getImageName(sole.photo_path)"
                style="position: absolute; top: 0"
              />
            </div>

            <div class="w-100">
              <h3 class="product__title">
                {{ item.name[activeLanguage.language] }}
              </h3>
              <span class="product__options d-block">
                Подошва: {{ sole.name }}
              </span>
              <span v-if="item.colors" class="product__options d-block">
                Цвет: {{ color.name }}
              </span>

              <div class="product__options d-flex flex-column">
                <!-- <pre>{{ color }}</pre> -->
                <span
                  v-for="(size, index) in item.sizes.filter(
                    (size) =>
                      size.soleId === sole.id && size.colorId === color.id
                  )"
                  :key="index"
                >
                  Размер: {{ `${size.name} * ${size.count}` }}
                </span>
              </div>
              <h3>
                <span class="count">{{
                  item.sizes
                    .filter(
                      (size) =>
                        size.soleId === sole.id && size.colorId === color.id
                    )
                    .reduce((sum, curr) => (sum += curr.count), 0)
                }}</span>
                <span class="delimetr"> x </span>
                <span class="price">
                  <span class="currency">$</span>
                  <span class="value-price">{{
                    item.sizes.filter(
                      (size) =>
                        size.soleId === sole.id && size.colorId === color.id
                    )[0] &&
                      item.sizes.filter(
                        (size) =>
                          size.soleId === sole.id && size.colorId === color.id
                      )[0].price
                  }}</span>
                </span>
              </h3>
            </div>
          </div>
          <v-btn
            class="px-0 d-flex align-start"
            icon
            :ripple="false"
            @click.stop="
              removeItem({
                type: item.price_type,
                itemId: item.id,
                colorId: color.id,
                soleId: sole.id,
                soles: item.soles,
              })
            "
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </div>
      </div>
    </template>
  </div>
</template>
<script>
import { createNamespacedHelpers } from 'vuex'
import { getImageName } from '@/utils/utils'
const { mapMutations } = createNamespacedHelpers('cart')
const {
  mapActions: Actions_brand_doubt,
  mapState: State_brand_doubt,
  mapMutations: Mutations_brand_doubt,
} = createNamespacedHelpers('brandDoubt')
export default {
  name: 'SideCartAdminItem',
  props: {
    item: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      baseUrl: '',
      activeLanguage: '',
    }
  },
  methods: {
    getImageName,
    ...mapMutations(['REMOVE_ITEM_FROM_SIDE_CART']),
    ...Actions_brand_doubt(['removeSavedBrands']),
    findSolelessItemSum(sizes) {
      // return sizes.reduce((sum, curr) => (sum += curr.count), 0)
    },
    findSolefullItemSum(sizes) {
      // return sizes.reduce((sum, curr) => (sum += curr.count), 0)
    },
    removeItem(payload) {
      // const brands = payload?.soles?.map((item) => item.selected_brands)?.flat()
      // this.removeSavedBrands(brands)
      this.REMOVE_ITEM_FROM_SIDE_CART(payload)
    },
  },
  mounted() {
    this.baseUrl = this.$store.state.auth.API_URL
    this.activeLanguage = this.$store.state.language
  },
}
</script>
<style lang="scss" scoped>
.side-cart {
  position: fixed;
  top: 0;
  right: 0;
  width: 344px;
  height: 100%;
  z-index: 7;
  &__container {
    display: flex;
    flex-direction: column;
    height: 100%;
  }
  &__body {
    flex-grow: 1;
    overflow: auto;
  }
  &__footer {
    .columns {
      span {
        font-size: 24px;
        color: #666666;
        font-family: 'Roboto Condensed';
      }
      & > span {
        font-weight: 500;
        font-family: 'Alegreya Sans';
      }
    }
  }
  .product {
    &__title {
      font-size: 16px;
      margin-bottom: 6px;
    }
    &__options {
      font-size: 14px;
      color: #b2b2b2;
    }
    &__title,
    &__options {
      text-align: left;
    }
  }
  .view {
    background-color: #cfebcc;
  }
  .ordering {
    background-color: #eeeeee;
  }
}
.product {
  &__img {
    min-width: 100px;
    height: 100px;
    max-width: 100px;
    position: relative;
    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }
  &__title {
    font-size: 24px;
    font-weight: 500;
  }
  .count,
  .delimetr {
    font-size: 14px;
    color: #9a9a9a;
  }
  .price {
    font-size: 18px;
    color: #666666;
  }
}
.v-btn--icon:before {
  content: none;
}
</style>
